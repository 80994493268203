import * as Accordion from "@radix-ui/react-accordion";
import styles from "./LibraryContent.module.css";
// Images
import InlineSVG from "react-inlinesvg";
import icon_tableOfContents from './../../../assets/img/library/content/tableOfContents.svg'
import icon_notDone from './../../../assets/img/library/content/notDone.svg'
import icon_done from './../../../assets/img/library/content/done.svg'
import { Link, useParams } from "react-router-dom";

const MyAccordion = ({ dataChapters={} }) => {
    const { subject } = useParams()

    return (
        <Accordion.Root type="multiple" className={styles.accordionRoot}>
            {dataChapters.map((chapter) => (
                <Accordion.Item
                    key={chapter.order}
                    value={`item-${chapter.order}`}
                    className={styles.accordionItem}
                >
                    <Accordion.Header className={styles.accordionHeader}>
                        <Accordion.Trigger className={styles.accordionTrigger}>
                            {`${chapter?.order}. ${chapter?.name}`}
                            <ChevronIcon className={styles.chevron} />
                        </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Content className={styles.accordionContent}>
                        <ul>
                            {chapter?.topics?.map((topic) => (
                                <li 
                                    key={topic._id} 
                                    className={styles.topicItemWrapper}                                    
                                >
                                    <Link to={`/library/${subject}/${topic._id}`} className={styles.topicItemLink}  >
                                        {
                                            topic.hasExercise &&
                                            <InlineSVG src={topic.isDone ? icon_done : icon_notDone} /> 
                                        }
                                        
                                        {topic.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Accordion.Content>
                </Accordion.Item>
            ))}
        </Accordion.Root>
    );
};

const ChevronIcon = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <polyline points="6 9 12 15 18 9" />
    </svg>
);

const LibraryContent = ({ dataChapters, onTopicClick }) => {
    return(
        <>
            <div className={styles.tableOfContent}>
                <InlineSVG src={icon_tableOfContents} />
                <span>Content</span>
            </div>
            
            <MyAccordion dataChapters={dataChapters}  onTopicClick={onTopicClick} />
        </>
    )
}

export default LibraryContent;
