import styles from './SLayout.module.css'
import { useEffect, useRef, useState } from 'react'
import { Link, Outlet } from "react-router-dom"
// 
import SNav from './common/SNav'
import SFooter from "./common/SFooter"



// Helmet
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { selectIsDefaultThemeColor } from '../../features/helmet/helmetSlice'
// RTK QUERY
import { useRTKQuery_Chats } from "./../../app/Initial_RTKQueryHooks/useRTKQuery_Chats"; 

const SLayout = () => {
    // Load chats
    useRTKQuery_Chats()

    const isDefaultThemeColor = useSelector(state => state.helmet.theme_color.isDefault);
    const defaultThemeColor = useSelector(state => state.helmet.theme_color.defaultColor);
    const newThemeColor = useSelector(state => state.helmet.theme_color.newColor);
    
    const userTribe = useSelector(state => state.auth.tribe);

    const isModalOpen = useSelector(selectIsDefaultThemeColor);

    const content = (
        <>
            {isDefaultThemeColor
                ?   <Helmet>
                    <meta name="theme-color" content={defaultThemeColor} />
                    </Helmet>
                : <Helmet>
                        <meta name="theme-color" content={newThemeColor} />
                    </Helmet>
            }
            
            <div className={`${styles.s_layout} ${isModalOpen ? '' : styles.blur} ${styles[`tribe_${userTribe}`]}`}>
                    <Outlet />
            </div>
        </>
    )

    return content
}

export default SLayout