import styles from "./Modal_offCanvas_Notifications.module.css";

// layout
import PopupBottomSwipeLayout from "../../Layouts/PopupBottomSwipeLayout";
import PopupLeftSwipeLayout from "../../Layouts/PopupLeftSwipeLayout";
// 
// import useChatLogic from "./hooks/useChatLogic";
import SearchUserListView from "./views/SearchUserListView";
import NewChatView from "./views/NewChatView";
import ChatListView from "./views/ChatListView";
import ConversationView from "./views/ConversationView";

// redux
import { useSelector } from "react-redux";

const ModalOffCanvasNotifications = () => {
    // chat
    const selectedChat = useSelector((state) => state.chat.selectedChat);
    // device
    const { isMobile, isDesktop } = useSelector((state) => state.device);


//   const {
//     chatSearchTerm,
//     setChatSearchTerm,
//     userSearchTerm,
//     setUserSearchTerm,
//     debouncedUserSearchTerm,
//     displayedSearchResults,
//     selectedUserForNewChat,
//     localMessages,
//     messageInput,
//     setMessageInput,
//     handleChatItemClick,
//     handleBackToChats,
//     handleSendMessage,
//     handleUserSelect,
//     filteredChatList,
//     isLoading,
//     isError,
//   } = useChatLogic();


  const renderLayout = () => {
        if (selectedChat) {
          return (
            <ConversationView />
          );
        }
        // if (debouncedUserSearchTerm.trim().length > 0) {
        //   return (
        //     <SearchUserListView
        //       searchTerm={userSearchTerm}
        //       onSearchChange={(e) => setUserSearchTerm(e.target.value)}
        //       users={displayedSearchResults}
        //       onUserSelect={handleUserSelect}
        //     />
        //   );
        // }
        // if (selectedUserForNewChat) {
        //   return <NewChatView user={selectedUserForNewChat} onBack={handleBackToChats} />;
        // }
        return (
            <ChatListView
                // chatSearchTerm={chatSearchTerm}
                // onSearchChange={(e) => setChatSearchTerm(e.target.value)}
                // chatList={filteredChatList}
                // unreadCounts={{}} // Replace with unread counts from Redux if available.
                // isLoading={isLoading}
                // isError={isError}
                // onChatSelect={handleChatItemClick}
            />
        );
  };

  return isMobile ? (
    <PopupBottomSwipeLayout classNameLayout={styles.popup__layout_bottom}>
      {renderLayout()}
    </PopupBottomSwipeLayout>
  ) : (
    <PopupLeftSwipeLayout classNameLayout={styles.popup__layout_left}>
      {renderLayout()}
    </PopupLeftSwipeLayout>
  );
};

export default ModalOffCanvasNotifications;