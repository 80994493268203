import { useModalWindow } from "react-modal-global";
import styles from "./PopupExerciseFullScreen.module.css";

function PopupExerciseFullScreen(props) {
  const modal = useModalWindow();
  return (
        <>
        <div className={styles.wrapper}>

                {/* <div className={styles.backdrop} onClick={closeModal}></div> */}
                <div className={styles.popup__layout}>
                <div className={props.classNameLayout}>
                    {props.children}
                </div>
                </div>

        </div>
        </>
  );
}

export default PopupExerciseFullScreen;
