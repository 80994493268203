import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const detailsAdapter = createEntityAdapter({})

const initialState = detailsAdapter.getInitialState()

export const mathApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
       
        // 
        getProblem: builder.query({
            query: () => '/math',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        postProblem: builder.mutation({
            query: initialOrder => ({
                url: '/math',
                method: 'POST',
                body: {
                    ...initialOrder,
                },
            }),
        }),
        sardarGetCode: builder.mutation({
            query: initialOrder => ({
                url: '/sardar/code',
                method: 'POST',
                body: {
                    ...initialOrder,
                },
            }),
        }),
    })
        
})

export const {
    // 
    useGetProblemQuery,
    usePostProblemMutation,
    useSardarGetCodeMutation
} = mathApiSlice