import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// ROUTER
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// REDUX
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './app/store';
// Helmet
import { HelmetProvider } from 'react-helmet-async';

// Scroll To Top Fix
import ScrollToTop from './customHooks/ScrollToTop'
// Socket IO
import { SocketProvider } from './app/SocketContext'



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <>
    
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ScrollToTop />
            <SocketProvider>
              <Routes>
                <Route path='/*' element={<App />} />
              </Routes>
            </SocketProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </HelmetProvider>
    </>

  // </React.StrictMode>
);
