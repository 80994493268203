import { ModalController } from 'react-modal-global';

// Login
import Modal_offCanvas_Left from './Modal_offCanvas/Modal_offCanvas_Left/Modal_offCanvas_Left';
import Modal_offCanvas_Right from './Modal_offCanvas/Modal_offCanvas_Right/Modal_offCanvas_Right';
import Modal_offCanvas_Search from './Modal_offCanvas/Modal_offCanvas_Search/Modal_offCanvas_Search';
import Modal_offCanvas_Notifications from './Modal_offCanvas/Modal_offCanvas_Notifications/Modal_offCanvas_Notifications';
import Modal_offCanvas_Tribe from './Modal_offCanvas/Modal_offCanvas_Tribe/Modal_offCanvas_Tribe';
import Modal_offCanvas_Games from './Modal_offCanvas/Modal_offCanvas_Games/Modal_offCanvas_Games';
import Modal_offCanvas_Study from './Modal_offCanvas/Modal_offCanvas_Study/Modal_offCanvas_Study';
// import VModalPatientCreateEdit from './Patient/VModalPatientCreateEdit'
// import VModalPatientRecordAdd from './Patient/VModalPatientRecordAdd';

const Modal_1 = new ModalController({
  defaultParams: {},
  components: {
              
              Modal_offCanvas_Left,
              Modal_offCanvas_Right,

              Modal_offCanvas_Search,
              Modal_offCanvas_Notifications,
              Modal_offCanvas_Tribe,
              Modal_offCanvas_Games,
              Modal_offCanvas_Study,            
              },
});

export default Modal_1;
