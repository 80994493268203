import { useDispatch, useSelector } from "react-redux";
import { useGetUserChatsQuery } from "../../features/chat/chatApiSlice.js";
import { addMessage, setChats, addChat, addOnlineUser, removeOnlineUser, setOnlineUsers } from "./../../features/chat/chatSlice.js";
import { useEffect } from "react";
// socket
import { useSocket } from "../SocketContext.js";


export const useRTKQuery_Chats = () => {
    const dispatch = useDispatch();
    const chatList = useSelector((state) => state.chat.chatList);
    const socket = useSocket();

    // RTK Query: fetch chats.
    const { data: chats, isLoading, isError } = useGetUserChatsQuery();

    useEffect( ()=> {
        if(isLoading){
            dispatch(setChats([])); // Store chats in Redux
        }
    }, [isLoading])
    
    useEffect(() => {
        if (chats) {
            const chatList = chats.ids.map(id => chats.entities[id]);
            dispatch(setChats(chatList)); // Store chats in Redux
        }
    }, [chats]);


    console.log(chatList)
    // RECEIVE MESSAGE ON FRONT
    useEffect(() => {
        if (!socket) return;
        console.log(chatList)
        const handleReceiveMessage = (msg) => {
            console.log(msg)
            // Check if a chat with this msg.chatId already exists.
            const existingChat = chatList.find(chat => chat._id === msg.chatId);
            if (existingChat) {
                // Update the existing chat's lastMessage and updatedAt.
                const updatedChat = { 
                    ...existingChat, 
                    lastMessage: msg.text, 
                    updatedAt: msg.updatedAt
                };
                dispatch(addChat(updatedChat));
            } else {
                // Create a new chat entry using msg.participants (if available).
                const newChat = {
                    _id: msg.chatId,
                    participants: msg.participants,
                    lastMessage: msg.text,
                    updatedAt: msg.updatedAt
                };
                dispatch(addChat(newChat));
            }
            // Finally, add the received message.
            dispatch(addMessage(msg));
        };
    
        socket.on("receiveMessage_onFront", handleReceiveMessage);
        return () => {
          socket.off("receiveMessage_onFront", handleReceiveMessage);
        };
    }, [socket]);

    // GET ONLINE USERS
    useEffect(() => {
        if (!socket) return;
       
        const handleGetOnlineUsers = (onlinePartners) => {
            dispatch(setOnlineUsers(onlinePartners));
        };

        const handlePartnerOnline = (partnerId) => {
          dispatch(addOnlineUser(partnerId));
        };
        const handlePartnerOffline = (partnerId) => {
          dispatch(removeOnlineUser(partnerId));
        };
    
        socket.on("getOnlineUsers", handleGetOnlineUsers);
        socket.on("chatPartnerOnline", handlePartnerOnline);
        socket.on("chatPartnerOffline", handlePartnerOffline);
    
        return () => {
            socket.off("getOnlineUsers", handleGetOnlineUsers);
            socket.off("chatPartnerOnline", handlePartnerOnline);
            socket.off("chatPartnerOffline", handlePartnerOffline);
        };
      }, [socket, dispatch]);
    
    return null; // Doesn't render anything, just initializes chats
}