import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

// Chat adapter and initial state.
const chatAdapter = createEntityAdapter({
  selectId: (chat) => chat._id,
});
const initialChatState = chatAdapter.getInitialState();

// Messages adapter and initial state.
const messagesAdapter = createEntityAdapter({
  selectId: (message) => message._id,
});
const initialMessagesState = messagesAdapter.getInitialState();

// Inject endpoints into the base apiSlice.
export const chatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Existing endpoint: getUserChats.
    getUserChats: builder.query({
      query: () => "/chat",
      validateStatus: (response, result) =>
        response.status === 200 && !result.isError,
        keepUnusedDataFor: 5,
        providesTags: (result) =>
          result && result.ids
            ? [
                ...result.ids.map((id) => ({ type: "Chat", id })),
                { type: "Chat", id: "LIST" },
              ]
            : [{ type: "Chat", id: "LIST" }],
        transformResponse: (responseData) =>
        chatAdapter.setAll(initialChatState, responseData),
    }),

    // Existing endpoint: searchUsers.
    searchUsers: builder.query({
      query: (searchTerm) => `/users/search?query=${searchTerm}`,
      keepUnusedDataFor: 2,
      async onCacheEntryAdded(arg, { queryFulfilled, signal }) {
        if (signal && typeof signal.addEventListener === "function") {
          signal.addEventListener("abort", () =>
            console.log("Abort event received for searchUsers:", arg)
          );
        }
        try {
          await queryFulfilled;
        } catch (err) {
          if (err.name === "AbortError") {
            console.log("searchUsers query aborted:", arg);
          } else {
            console.error("Error in searchUsers query:", err);
          }
        }
      },
    }),

    // getMessagesByChatId endpoint.
    getMessagesByChatId: builder.query({
        query: (chatId) => `/chat/messages/${chatId}`,
        validateStatus: (response, result) =>
            response.status === 200 && !result.isError,
        keepUnusedDataFor: 60,
        providesTags: (result, error, chatId) =>
            result && result.ids
            ? [
                ...result.ids.map((id) => ({ type: "Message", id })),
                { type: "Message", id: "LIST" },
                ]
            : [{ type: "Message", id: "LIST" }],
        transformResponse: (responseData) => {
            // Normalize messages and then return an array using the adapter's selector.
            const normalized = messagesAdapter.setAll(initialMessagesState, responseData);
            return messagesAdapter.getSelectors().selectAll(normalized);
        },
    }),
    // New endpoint: createMessage.
    createMessage: builder.mutation({
        query: ({ chatId, text }) => ({
          url: "/messages/create",
          method: "POST",
          body: { chatId, text },
        }),
    }),



  }),
});

// Export hooks for usage in functional components.
export const {
  useGetUserChatsQuery,
  useSearchUsersQuery,
  useLazySearchUsersQuery,
  useGetMessagesByChatIdQuery,
  useCreateMessageMutation,
} = chatApiSlice;

// Selectors for chats.
export const selectChatResult = chatApiSlice.endpoints.getUserChats.select();

const selectChatData = createSelector(
  selectChatResult,
  (chatResult) => chatResult.data ?? initialChatState
);

export const {
  selectAll: selectAllChats,
  selectById: selectChatById,
} = chatAdapter.getSelectors((state) => selectChatData(state));

export { chatAdapter };