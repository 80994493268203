import { createSlice } from "@reduxjs/toolkit";

const exerciseSlice = createSlice({
    name: "exercise",
    initialState:   {
                        exerciseInfo: {},
                        exerciseStats: {},
                        sessionStats: {},
                        shouldTriggerSessionStatsRequest: false
                    },
    reducers:   {
                    setTopicData: (state, action) => {
                        const dataTopicContent = action.payload;
                        state.exerciseInfo = dataTopicContent.exerciseInfo
                        state.exerciseStats = dataTopicContent.exerciseStats
                        state.sessionStats = dataTopicContent.sessionStats
                        // state.theme_color.newColor = color
                    },
                    triggerSessionStatsRequest: (state, action) => {
                        state.shouldTriggerSessionStatsRequest = true;
                    },
                    resetSessionStatsRequest: (state) => {
                        state.shouldTriggerSessionStatsRequest = false;
                    },
                  
                    
                },
});

export const { setTopicData, triggerSessionStatsRequest, resetSessionStatsRequest } = exerciseSlice.actions;

export const selectExerciseInfo = (state) => state.exercise.exerciseInfo
export const selectExerciseStats = (state) => state.exercise.exerciseStats
export const selectEessionStats = (state) => state.exercise.sessionStats
export const selectShouldTriggerSessionStatsRequest = (state) => state.exercise.shouldTriggerSessionStatsRequest

export default exerciseSlice.reducer;