import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDeviceType } from "./deviceSlice";

const useDeviceType = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 600;
      const isDesktop = window.innerWidth >= 601;
      dispatch(setDeviceType({ isMobile, isDesktop }));
    };

    // Initial check
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);
};

export default useDeviceType;