import React, { useState, useEffect } from "react";
import styles from "./ChatListView.module.css";
import SearchInput from "./../components/SearchInput";
import ChatListItem from "./../components/ChatListItem";
import { useSelector, useDispatch } from "react-redux";
import { selectUserId } from "../../../../../features/auth/authSlice";
import { setSelectChat } from "../../../../../features/chat/chatSlice";
import { useLazySearchUsersQuery } from "../../../../../features/chat/chatApiSlice";

const ChatListView = () => {
  const dispatch = useDispatch();
  const chatList = useSelector((state) => state.chat.chatList);
  const currentUserId = useSelector(selectUserId);

  // Local state to toggle between normal chat mode and new-chat mode.
  const [isNewChatMode, setIsNewChatMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // Setup lazy query hook to search for users.
  const [triggerSearch, { data: apiSearchResults, isFetching, error }] =
    useLazySearchUsersQuery();

  // When in new chat mode, if searchQuery is at least 3 characters, trigger the API search.
  useEffect(() => {
    if (isNewChatMode && searchQuery.trim().length >= 3) {
      const promise = triggerSearch(searchQuery);
      promise.then((result) => {
        if (result.data) {
          setSearchResults(result.data);
        }
      });
      return () => {
        if (promise.abort) promise.abort();
      };
    } else if (isNewChatMode) {
      // Reset results if query is too short.
      setSearchResults([]);
    }
  }, [isNewChatMode, searchQuery, triggerSearch]);

  // In normal mode, filter the local chatList based on searchQuery.
  const filteredChatList = !isNewChatMode
    ? chatList.filter((chat) => {
        if (!searchQuery.trim()) return true;
        const query = searchQuery.toLowerCase();
        return chat.participants
          .filter((participant) => participant._id !== currentUserId)
          .some(
            (participant) =>
              participant.first_name.toLowerCase().includes(query) ||
              participant.last_name.toLowerCase().includes(query)
          );
      })
    : [];

  // Determine which list to display: API search results in new-chat mode, or filtered chatList otherwise.
  const displayList = isNewChatMode ? searchResults : filteredChatList;

  // Handlers to toggle new chat mode.
  const handleNewChatClick = () => {
    setIsNewChatMode(true);
    setSearchQuery("");
  };
  const handleCloseNewChat = () => {
    setIsNewChatMode(false);
    setSearchQuery("");
  };

  // When clicking an item, if in new-chat mode then the item represents a user,
  // so check for an existing chat for that user. If none, create a new temporary chat.
  const handleChatItemClick = (item) => {
    if (isNewChatMode) {
      const existingChat = chatList.find(
          (chat) =>
            chat.participants &&
            chat.participants.some(
              (p) => p._id === item._id && p._id !== currentUserId
            )
      );
      if (existingChat) {
          dispatch(setSelectChat(existingChat));
      } else {
          const newChat = {
            _id: Date.now().toString(), // Temporary ID.
            participants: [{ _id: currentUserId }, item],
            isNew: true,
            currentUserId,
          };
          dispatch(setSelectChat(newChat));
      }
    } else {
      dispatch(setSelectChat(item));
    }
  };
  
  return (
    <div className={styles.chatListContainer}>
      <div className={styles.header_container}>
        <div className={styles.header}>
          <h2 className={styles.title}>{isNewChatMode ? "New Chat" : "Chats"}</h2>
          {isNewChatMode ? (
            <button className={styles.btn_closeNewChat} onClick={handleCloseNewChat}>
                <svg width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z" fill="black" />
                </svg>
            </button>
          ) : (
            <button className={styles.btn_newChat} onClick={handleNewChatClick}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="black" />
                </svg>
            </button>
          )}
        </div>
        {isNewChatMode &&
          <SearchInput
          placeholder={isNewChatMode ? "Search users..." : "Search chats..."}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        }
        
      </div>

      <ul className={styles.chatList}>
            {displayList.length > 0 ? (
              displayList.map((item) => (
                <ChatListItem
                  key={item._id}
                  chat={item}
                  isSearchResult={isNewChatMode}
                  onClick={() => handleChatItemClick(item)}
                />
              ))
            ) : (
              <li>{isNewChatMode ? "No search results found" : "No chats"}</li>
            )}
      </ul>
    </div>
  );
};

export default ChatListView;