import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    isMobile: true,
    isDesktop: false,
};

const deviceSlice = createSlice({
  name: "device",
  initialState: INITIAL_STATE,
  reducers: {
        setDeviceType: (state, action) => {
        // action.payload should be an object { isMobile, isDesktop }
        if (
            typeof action.payload.isMobile !== "boolean" ||
            typeof action.payload.isDesktop !== "boolean"
        ) {
            console.warn(
            "Payload must contain boolean values for isMobile and isDesktop. Ignoring invalid value:",
            action.payload
            );
            return;
        }
        state.isMobile = action.payload.isMobile;
        state.isDesktop = action.payload.isDesktop;
        },
  },
});

export const { setDeviceType } = deviceSlice.actions;
export default deviceSlice.reducer;