import styles from './SBreadcrumbs.module.css'
import InlineSVG from 'react-inlinesvg'
// Images
import icon_arrowLeft from "./../../../assets/img/library/breadcrumbs/arrow_left.svg"
import icon_content from "./../../../assets/img/library/breadcrumbs/content.svg"
import icon_home from "./../../../assets/img/library/breadcrumbs/home.svg"
import { Link } from 'react-router-dom'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import { setIsLeftOffcanvasCollapsed } from "./../../../features/library/librarySlice";

const SBreadcrumbs = ({ returnTo }) => {
    const dispatch = useDispatch()
    const isLeftOffcanvasCollapsed = useSelector(state => state.library.isLeftOffcanvasCollapsed);

    return (
        <section className={styles.breadcrumbs}>
            <div className={styles.left}>
                {
                    isLeftOffcanvasCollapsed 
                        ?   <button className={styles.link} onClick={()=> dispatch(setIsLeftOffcanvasCollapsed(false))}>
                                <InlineSVG src={icon_content} />
                                Content
                            </button>
                        :   <button className={styles.link} onClick={()=> dispatch(setIsLeftOffcanvasCollapsed(true))}>
                                <InlineSVG className={styles.arrowLeft} src={icon_arrowLeft} />
                                Закрыть
                            </button>
                }
            </div>
            <div className={styles.right}>
                
                
                <Link to="/dashboard" className={styles.wrapper}>
                    <InlineSVG src={icon_home} />
                </Link>
            </div>
        </section>
    )
}

export default SBreadcrumbs