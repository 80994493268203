import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const libraryAdapter = createEntityAdapter({});

const initialState = libraryAdapter.getInitialState();

export const libraryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
      getSubjects: builder.query({
          query: () => '/library/subjects',
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError;
          },
          keepUnusedDataFor: 5,
          transformResponse: (responseData) => {
            const loadedSubjects = responseData.map((subject) => {
              subject.id = subject._id; // Ensure the ID is properly mapped
              return subject;
            });
            return libraryAdapter.setAll(initialState, loadedSubjects);
          },
          providesTags: (result, error, arg) => {
            if (result?.ids) {
              return [
                { type: 'Subjects', id: 'LIST' },
                ...result.ids.map((id) => ({ type: 'Subject', id })),
              ];
            } else {
              return [{ type: 'Subjects', id: 'LIST' }];
            }
          },
      }),
      getSubjectContentBySubjectName: builder.query({
          query: (subjectId) => `/library/subjectContent/${subjectId}`,
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError;
          },
          keepUnusedDataFor: 5,
          transformResponse: (responseData) => {
              // Store subject-level data separately
              const subjectInfo = {
                name: responseData.name,
                introduction: responseData.introduction,
              };
            
              // Extract and normalize chapters
              const loadedChapters = Object.values(responseData.chapters).map((chapter) => ({
                ...chapter,
                id: chapter._id, // Ensure each chapter has a consistent `id` property
              }));
            
              return {
                subject: subjectInfo, // Separate subject-level data
                chapters: libraryAdapter.setAll(initialState, loadedChapters), // Normalized chapters
              };
          },
      }),
      getTopicContentByTopicId: builder.query({
        query: (topicId) => `/library/topicContent/${topicId}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
        keepUnusedDataFor: 5,
     
    }),
      
  }),
});

export const {
    useGetSubjectsQuery,
    useGetSubjectContentBySubjectNameQuery,
    useLazyGetTopicContentByTopicIdQuery

} = libraryApiSlice;

// Return the query result object
export const selectSubjectsResult = libraryApiSlice.endpoints.getSubjects.select();

// Creates memoized selector
const selectSubjectsData = createSelector(
    selectSubjectsResult,
  (subjectsResult) => subjectsResult.data ?? {} // Return empty object if data is undefined
);

// GetSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectSubjectsAll,
  selectById: selectSubjectById,
  selectIds: selectSubjectsIds,
} = libraryAdapter.getSelectors((state) => selectSubjectsData(state) ?? initialState);

