import { useState, useEffect, useRef } from 'react';
import styles from './VBattles.module.css';

const totalQuestions = 37;
const TIMER_DURATION = 10; // seconds per question; change this value to adjust timer

// Read URL parameter "timer" (on/off)
const urlParams = new URLSearchParams(window.location.search);
const timerEnabled = urlParams.get("timer") === "on";
// If no timer parameter is present, show landing page.
const initialShowLanding = !urlParams.has("timer");

const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

const VBattles = () => {
  // Landing page state
  const [showLanding, setShowLanding] = useState(initialShowLanding);

  // Modal & Quiz states
  const [userName, setUserName] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questions, setQuestions] = useState([]); // Array of question pairs
  const [selectedIndex, setSelectedIndex] = useState(null); // Tracks tapped option index
  const [results, setResults] = useState([]); // Accumulate results for each question
  const [quizFinished, setQuizFinished] = useState(false); // Indicates quiz completion
  const [timeLeft, setTimeLeft] = useState(timerEnabled ? TIMER_DURATION : null);

  const timerIntervalRef = useRef(null);

  // Helper function to go back to landing page and reset states.
  const goBackToLanding = () => {
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    }
    window.history.replaceState({}, "", window.location.pathname);
    setUserName('');
    setShowModal(true);
    setCurrentQuestion(0);
    setQuestions([]);
    setSelectedIndex(null);
    setResults([]);
    setQuizFinished(false);
    setTimeLeft(timerEnabled ? TIMER_DURATION : null);
    setShowLanding(true);
  };

  useEffect(() => {
    // Build questions: for each question, load the "true" and "false" images and shuffle the pair.
    const qs = [];
    for (let i = 1; i <= totalQuestions; i++) {
      const numStr = String(i).padStart(3, '0');
      const trueImage = {
        src: require(`./../../../assets/battles/medium/${numStr}_true.png`),
        isCorrect: true,
      };
      const falseImage = {
        src: require(`./../../../assets/battles/medium/${numStr}_false.png`),
        isCorrect: false,
      };
      qs.push(shuffleArray([trueImage, falseImage]));
    }
    // Shuffle overall order of questions before setting state.
    setQuestions(shuffleArray(qs));
  }, []);

  // Setup the timer for each question when timerEnabled is true and after the name is submitted.
  useEffect(() => {
    if (!showModal && timerEnabled) {  // Timer starts only after modal is closed.
      setTimeLeft(TIMER_DURATION); // reset timer for new question
      if (timerIntervalRef.current) clearInterval(timerIntervalRef.current);
      const interval = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            timerIntervalRef.current = null;
            if (selectedIndex === null) {
              handleTimeOut();
            }
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      timerIntervalRef.current = interval;
      return () => clearInterval(interval);
    }
  }, [currentQuestion, timerEnabled, selectedIndex, showModal]);

  // Function to handle a timed-out question (no selection made in time).
  const handleTimeOut = () => {
    if (selectedIndex !== null) return;
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    }
    const currentResult = { question: currentQuestion + 1, selected: false };
    const updatedResults = [...results, currentResult];
    setResults(updatedResults);
    setTimeout(() => {
      if (currentQuestion < totalQuestions - 1) {
        setCurrentQuestion((prev) => prev + 1);
        setSelectedIndex(null);
      } else {
        const correctCount = updatedResults.filter((result) => result.selected === true).length;
        console.log('Sending results to backend:', {
          name: userName,
          correct: correctCount,
          total: totalQuestions,
          details: updatedResults,
        });
        setQuizFinished(true);
      }
    }, 400);
  };

  // Handle manual selection by the user.
  const handleSelect = (index, selected) => {
    if (selectedIndex !== null) return;
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    }
    setSelectedIndex(index);
    const currentResult = { question: currentQuestion + 1, selected: selected.isCorrect };
    const updatedResults = [...results, currentResult];
    setResults(updatedResults);
    setTimeout(() => {
      if (currentQuestion < totalQuestions - 1) {
        setCurrentQuestion((prev) => prev + 1);
        setSelectedIndex(null);
      } else {
        const correctCount = updatedResults.filter((result) => result.selected === true).length;
        console.log('Sending results to backend:', {
          name: userName,
          correct: correctCount,
          total: totalQuestions,
          details: updatedResults,
        });
        setQuizFinished(true);
      }
    }, 400);
  };

  if (quizFinished) {
    const correctCount = results.filter((result) => result.selected === true).length;
    return (
      <div className={styles.page}>
        <h2 className={styles.title}>Результаты</h2>
        <div className={styles.block}>
          <p>Спасибо, {userName}!</p>
          <p>
            Вы ответили правильно на {correctCount} из {totalQuestions} вопросов.
          </p>
          <button className={styles.finishButton} onClick={goBackToLanding}>
            FINISH
          </button>
        </div>
      </div>
    );
  }

  if (showLanding) {
    return (
      <div className={styles.landing}>
        <h1 className={styles.landingTitle}>BATTLES</h1>
        <a href="?timer=on" className={styles.startButton}>
          START
        </a>
      </div>
    );
  }

  return (
    <>
      {showModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h2>Введите ваше имя</h2>
            <input
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Ваше имя"
              className={styles.input}
            />
            <div>
              <button onClick={goBackToLanding} className={styles.cancelButton}>
                Cancel
              </button>
              <button onClick={() => userName && setShowModal(false)} className={styles.button}>
                Начать
              </button>
            </div>
          </div>
        </div>
      )}

      {!showModal && questions.length > 0 && (
        <div className={styles.page}>
          <button className={styles.exitButton} onClick={goBackToLanding}>
            X
          </button>
          {timerEnabled && (
            <div className={styles.timer}>
              {`00:${timeLeft < 10 ? `0${timeLeft}` : timeLeft}`}
            </div>
          )}
          <h2 className={styles.title}>
            Вопрос {currentQuestion + 1}/{totalQuestions}
          </h2>
          <div className={styles.block}>
            {questions[currentQuestion].map((img, index) => (
              <div
                key={index}
                className={styles.imgContainer}
                onClick={() => handleSelect(index, img)}
              >
                <img
                  src={img.src}
                  alt={`Option ${index + 1}`}
                  className={`${styles.img} ${
                    selectedIndex === index
                      ? img.isCorrect
                        ? styles.correct
                        : styles.wrong
                      : ''
                  }`}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default VBattles;