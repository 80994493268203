import { createSlice } from '@reduxjs/toolkit'

const initialState =    {
                            onlineUsers: [],      // Array of online users.
                            chatList: [],         // Array of chat objects.
                            selectedChat: null,   // Currently selected chat.
                            messages: {},         // { [chatId]: [ messages ] }
                            unreadCounts: {},     // { [chatId]: number }
                            totalUnread: 0,       // Total unread messages across chats.
                        };
  
  const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
                // online users
                setOnlineUsers(state, action) {
                    state.onlineUsers = action.payload;
                },
                addOnlineUser(state, action) {
                    const userId = action.payload;
                    if (!state.onlineUsers.includes(userId)) {
                    state.onlineUsers.push(userId);
                    }
                },
                removeOnlineUser(state, action) {
                    const userId = action.payload;
                    state.onlineUsers = state.onlineUsers.filter(id => id !== userId);
                },
                // chats
                setChats(state, action) {
                    state.chatList = action.payload; // Store fetched chats
                },
                addChat(state, action) {
                    const chat = action.payload;
                    const index = state.chatList.findIndex((c) => c._id === chat._id);
                    if (index >= 0) {
                        // Update the existing chat
                        state.chatList[index] = chat;
                    } else {
                        // Add the new chat
                        state.chatList.push(chat);
                    }
                },
                setSelectChat(state, action) {
                    state.selectedChat = action.payload; // Select a chat
                },
                resetSelectChat(state, action) {
                    state.selectedChat = null; // Select a chat
                },

                // messages
                setMessages(state, action) {
                    const { chatId, messages } = action.payload;
                    state.messages[chatId] = messages;
                },
                addMessage(state, action) {
                    const message = action.payload;
                    // Create an array for this chat if it doesn't exist yet.
                    if (!state.messages[message.chatId]) {
                      state.messages[message.chatId] = [];
                    }
                    state.messages[message.chatId].push(message);

                    // Update the lastMessage property in the corresponding chat in chatList.
                    const chatIndex = state.chatList.findIndex(chat => chat._id === message.chatId);
                    if (chatIndex !== -1) {
                        state.chatList[chatIndex].lastMessage = message;
                    }
                },

                // ... other reducers,
                incrementUnread(state, action) {
                    const { chatId } = action.payload;
                    state.unreadCounts[chatId] = (state.unreadCounts[chatId] || 0) + 1;
                    state.totalUnread += 1;
                },
                clearUnread(state, action) {
                    const { chatId } = action.payload;
                    state.totalUnread -= state.unreadCounts[chatId] || 0;
                    state.unreadCounts[chatId] = 0;
                },
    },
  });
  
  export const  { 
                    setOnlineUsers, addOnlineUser, removeOnlineUser,
                    setChats, addChat, setSelectChat, resetSelectChat,
                    setMessages,addMessage,
                    incrementUnread, clearUnread 
                } = chatSlice.actions;
  export default chatSlice.reducer;