import styles from './Modal_offCanvas_Left.module.css';
import { Link, useNavigate } from 'react-router-dom';

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from "../../../../customHooks/useScrollBlock";
import PopupLeftSwipeLayout from "../../Layouts/PopupLeftSwipeLayout";

// Helmet
import { setDefaultThemeColor } from '../../../../features/helmet/helmetSlice';
import { useDispatch } from 'react-redux';
// Redux
import { useSendLogoutMutation } from '../../../../features/auth/authApiSlice';
import { apiSlice } from '../../../../app/api/apiSlice';
import { useGetSubjectsQuery } from '../../../../features/library/libraryApiSlice';
import { useEffect, useRef, useState } from 'react';
// Images
import SVG from 'react-inlinesvg';
import icon_library from './../../../../assets/img/nav/library.svg'
const requireIcon = require.context(
    './../../../../assets/img/library/courses',
    false,
    /\.svg$/
  );
const Modal_offCanvas_Left = () => {
    const {
        data: dataSubjects,
        isLoading: isLoadingGetSubjects, 
        isSuccess: isSuccessGetSubjects,
        isError: isErrorGetSubjects,
        error: errorGetSubjects,
        refetch: refetchSubjects
    } = useGetSubjectsQuery(); // Automatically calls the API

    const [groupedSubjects, setGroupedSubjects] = useState({});

    useEffect(() => {
        if (isSuccessGetSubjects) {
            const subjects = dataSubjects.ids.map(id => dataSubjects.entities[id]);

            // Sort by category name
            subjects.sort((a, b) => a.categoryId.name.localeCompare(b.categoryId.name));

            // Group subjects by category
            const grouped = subjects.reduce((acc, subject) => {
                const categoryId = subject.categoryId._id;
                const categoryName = subject.categoryId.name;
              
                if (!acc[categoryId]) {
                    acc[categoryId] = {
                        categoryName,
                        subjects: []
                    };
                }
              
                acc[categoryId].subjects.push(subject.name);
                return acc;
            }, {});
            
            setGroupedSubjects(grouped);          
        }
    }, [dataSubjects, isSuccessGetSubjects]);

    // Hooks for modal and scroll handling
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();

    // Redux dispatch and theme handling
    const dispatch = useDispatch();
    const defaultThemeColor = () => dispatch(setDefaultThemeColor());

    // Navigation
    const navigate = useNavigate();

    // Logout mutation and states
    const [sendLogout] = useSendLogoutMutation();

    /**
     * Logs the user out, resets API state, and navigates to the home page.
     * @param {Event} e - The logout button click event.
     */
    const logout = async (e) => {
        e.preventDefault();
        try {
            const result = await sendLogout().unwrap();

            navigate('/');
            dispatch(apiSlice.util.resetApiState());
        } catch (error) {
            console.log(error);
        }
    };

    const popupRef = useRef(null);

    const handleCloseModal = () => {
        if (popupRef.current) {
        popupRef.current.closeModal(); // Call the child's closeModal method
        }
    };
    
    function IconComponent({ fileName }) {
        fileName = fileName.replace(/\s+/g, '_').toLowerCase();
        const iconPath = requireIcon(`./${fileName}.svg`);
      
        return (
            <SVG src={iconPath} alt={`${fileName} icon`} />
        );
    }

    const renderLayout = () => (
        <PopupLeftSwipeLayout classNameLayout={styles.popup__layout_right} ref={popupRef}>
            <div className={styles.header_container}>
                <div className={styles.header_title}>
                    <SVG className={styles.libraryIcon} src={icon_library} alt="Library Icon" />
                    <p>Library</p>
                </div>
                <button
                    className={styles.popup_layout__close}
                    type="button"
                    onClick={handleCloseModal}
                >
                    &#9587;
                </button>
            </div>
            <div className={styles.library_list}>
                {Object.entries(groupedSubjects).map(([categoryId, category]) => (
                    <div key={categoryId} className={styles.categoryBlock}>
                        <h2 className={styles.categoryTitle}>{category.categoryName}</h2>
                        <ul>
                            {category.subjects.map((subject, index) => (
                                <li key={index} className={styles.subject_block}>
                                    {/* Use Link to navigate to another page */}
                                    <Link onClick={handleCloseModal} to={`/library/${encodeURIComponent(subject.toLowerCase())}`} className={styles.subject_item}>
                                        <IconComponent fileName={subject} />
                                        <span>{subject}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </PopupLeftSwipeLayout>
    );

    return renderLayout();
};

export default Modal_offCanvas_Left;
