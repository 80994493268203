import styles from './Modal_ExercisePre.module.css';
import { useEffect, useState } from 'react';

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from "../../../../customHooks/useScrollBlock";
import PopupExerciseFullScreen from "../../Layouts/PopupExerciseFullScreen";

// Helmet
import { setDefaultThemeColor } from '../../../../features/helmet/helmetSlice';
import { useDispatch } from 'react-redux';
// Redux
import { useSendLogoutMutation } from '../../../../features/auth/authApiSlice';
import { apiSlice } from '../../../../app/api/apiSlice';
// Images
import icon_coin from './../../../../assets/img/exercise/coin.svg'
import icon_exit from './../../../../assets/img/exercise/exit.svg'

// continue
import icon_pause_continue from './../../../../assets/img/exercise/pause_continue.svg';
import icon_timer from './../../../../assets/img/exercise/timer.svg';
import icon_offline from './../../../../assets/img/exercise/offline.svg';
import icon_arrow_left from './../../../../assets/img/exercise/arrow_left.svg';
import icon_blue from './../../../../assets/img/exercise/blue.svg';


// Redux
import { useStartExerciseMutation } from '../../../../features/exercise/exerciseApiSlice';
// 
import RadioGroup from "./RadioGroup";
import CustomSwitch from './CustomSwitch';
import ExStandardKb from '../ExStandardKb/ExStandardKb';

const Modal_ExercisePre = ({ dataTopicContent }) => {
   
    // Hooks for modal and scroll handling
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();

    // 
    // const [firstModalLayout, setFirstModalLayout] = useState(true)
    // useEffect(()=>{
    //     setFirstModalLayout(initialStart)
    // }, [modal.closed])

    // Redux dispatch and theme handling
    const dispatch = useDispatch();
    const defaultThemeColor = () => dispatch(setDefaultThemeColor());

    const closeModal = () => {
        // setTimeout(function(){allowScroll()}, 150);
        defaultThemeColor();
        // allowScroll();
        modal.close();
    };

    //
    const [startExercise, {
        data: dataExercise,
        isLoading: isExerciseLoading, 
        isSuccess: isExerciseSuccess, 
        isUninitialized: isExerciseUninitialized,
        isError: isExerciseError, 
        error: errorExercise
    }] = useStartExerciseMutation();

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            console.log('enter')
            // checkAnswer(); // Trigger checkAnswer on Enter key press
        }
    };


    const [group1, setGroup1] = useState("10");
    const [isTimeLimited, setIsTimeLimited] = useState(true);
    const [timeType, setTimeType] = useState('perQuestion');
    const [group2, setGroup2] = useState("10");
    const [group3, setGroup3] = useState("standardKeyboard");
    
    const handleExerciseStart = () => {
        const libraryExerciseId = dataTopicContent?.libraryExerciseId ?? null;      

        // console.log({
        //         exerciseId: libraryExerciseId,
        //         sessionSettings: {
        //             numberOfQuestions: group1,
        //             isTimeLimited,  // shorthand property (same as isTimeLimited: isTimeLimited)
        //             timeType,       // shorthand property (same as timeType: timeType)
        //             timeLimit: group2,
        //             inputType: group3,
        //         },
        // })
        
        if (isExerciseUninitialized && libraryExerciseId) {
            startExercise({
                    exerciseId: libraryExerciseId,
                    sessionSettings: {
                        numberOfQuestions: group1,
                        isTimeLimited,  // shorthand property (same as isTimeLimited: isTimeLimited)
                        timeType,       // shorthand property (same as timeType: timeType)
                        timeLimit: group2,
                        inputType: group3,
                    },
            });
            
        } else {
            console.warn("Cannot start exercise: either already initialized or exerciseId is missing.");
        }
      };

    const RenderLayout = () => (
        <>
            <div className={styles.frame_start}>
                {/* HEADER */}
                <div className={styles.header_main}>
                    {/* left */}
                    <div className={styles.left}>
                        <div onClick={closeModal} className={styles.transparent_button}>
                            <img src={icon_arrow_left} />
                        </div>
                        <div className={styles.header_title}>
                            <h2 className={styles.topicName}>{dataTopicContent.topicName}</h2>
                            <h3 className={styles.chapterName}>{dataTopicContent.chapterName}</h3>
                        </div>
                    </div>
                    {/* right */}
                    <div className={styles.transparent_button}>
                        ?
                    </div>

                </div>

                {/* BODY */}
                <div className={styles.body}>
                    
                    {/* Quantity */}
                    <div className={styles.setting_block}>
                        <div className={styles.settings_header}>
                            <p>Количество</p>
                            {/* i */}
                        </div>
                        
                        <RadioGroup
                            title="Select a Card"
                            options={[
                                        {
                                            value: "5",
                                            content: (
                                            <div className="custom-card">
                                                <h4>5 вопросов</h4>
                                                <p>stars</p>
                                            </div>
                                            ),
                                        },
                                        {
                                            value: "10",
                                            content: (
                                            <div className="custom-card">
                                                <h4>10 вопросов</h4>
                                                <p>stars</p>
                                            </div>
                                            ),
                                        },
                                        {
                                            value: "20",
                                            content: (
                                            <div className="custom-card">
                                                <h4>20 вопросов</h4>
                                                <p>stars</p>
                                            </div>
                                            ),
                                        },
                                    ]}
                            groupName="questions"
                            onChange={setGroup1}
                            customClass="style-cards"
                            defaultValue={group1}
                        />
                        
                        
                    </div>
                    
                    {/* /////////////////////////////////////////////////////////////////////// */}
                    {/* Time Per Question or Session */}
                    {/* /////////////////////////////////////////////////////////////////////// */}
                    <div className={styles.settings_header}>
                        <p>Лимит времени</p>
                        {/* i */}
                        <CustomSwitch onChange={setIsTimeLimited} defaultChecked={isTimeLimited} />
                    </div>
                    
                    {!isTimeLimited &&
                        (<>
                            <p className={styles.noTimeLimit}>Нет ограничений по времени   </p>
                             
                        </>)
                    }
                    {/* /////////////////////////////////////////////////////////////////////// */}
                    {/* Time Per Question or Session */}
                    {/* /////////////////////////////////////////////////////////////////////// */}


                    {isTimeLimited &&
                        (<>
                            <div className={styles.setting_block}>

                                <RadioGroup
                                    title="Select a Card"
                                    options={[
                                                {
                                                    value: "perQuestion",
                                                    content: (
                                                    <div className="custom-card">
                                                        <h4>На вопрос</h4>
                                                    </div>
                                                    ),
                                                },
                                                {
                                                    value: "perSession",
                                                    content: (
                                                    <div className="custom-card">
                                                        <h4>На сессию</h4>
                                                    </div>
                                                    ),
                                                },
                                            ]}
                                    groupName="timeType"
                                    onChange={setTimeType}
                                    customClass="style-cards"
                                    defaultValue={timeType}
                                />
                            </div>
                            
                            {/* /////////////////////////////////////////////////////////////////////// */}
                            {/* Time Limit */}
                            {/* /////////////////////////////////////////////////////////////////////// */}

                            <div className={styles.setting_block}>
                                <RadioGroup
                                    title="Select a Card"
                                    options={[
                                                {
                                                    value: "5",
                                                    content: (
                                                    <div className="custom-card">
                                                        <h4>5 секунд</h4>
                                                        <p>stars</p>
                                                    </div>
                                                    ),
                                                },
                                                {
                                                    value: "10",
                                                    content: (
                                                    <div className="custom-card">
                                                        <h4>10 секунд</h4>
                                                        <p>stars</p>
                                                    </div>
                                                    ),
                                                },
                                                {
                                                    value: "20",
                                                    content: (
                                                    <div className="custom-card">
                                                        <h4>20 секунд</h4>
                                                        <p>stars</p>
                                                    </div>
                                                    ),
                                                },
                                            ]}
                                    groupName="timeLimit"
                                    onChange={setGroup2}
                                    customClass="style-cards"
                                    defaultValue={group2}
                                />
                            </div>
                        </>)
                    }
                    
                    {/* Input Type */}
                    <div className={styles.setting_block}>
                        <div className={styles.settings_header}>
                            <p>Способ ввода</p>
                            {/* i */}
                        </div>

                        <RadioGroup
                            title="Select a Card"
                            options={[
                                        {
                                            value: "mcq_4",
                                            content: (
                                            <div className="custom-card">
                                                <h4>MCQ 4</h4>
                                            </div>
                                            ),
                                        },
                                        {
                                            value: "standardKeyboard",
                                            content: (
                                            <div className="custom-card">
                                                <h4>Клавиатура</h4>
                                            </div>
                                            ),
                                        },
                                    ]}
                            groupName="inputType"
                            onChange={setGroup3}
                            customClass="style-cards"
                            defaultValue={group3}
                        />
                    </div>
                </div>     

                {/* FOOTER */}
                <div className={styles.footer}>
                    <button onClick={handleExerciseStart} className={styles.btn_start}>
                        Начать
                    </button>
                </div>
                
            </div>
        </>
    );

    const ContinueSession = () => (
        <>
            <div className={styles.frame_continue}>
                {/* header */}
                <div className={styles.header}>
                    <p className={styles.header_text_grey}>Практика прервана.</p>
                    <p className={styles.header_text_blue}>Хотите продолжить ?</p>
                </div>
                {/* stats */}
                <div className={styles.stats}>
                    {/* left */}
                    <div className={styles.stats_block_left}>
                        <img src={icon_pause_continue} alt="paused" />
                    </div>
                    {/* right */}
                    <div className={styles.stats_block_right}>
                        <h2 className={styles.stats_title}>Текущий результат</h2>
                        {/* time */}
                        <div className={styles.row}>
                            <div className={styles.stats_left}>
                                <img className={styles.stats_icon} src={icon_timer} alt="paused" />
                                <p className={styles.stats_label}>Время</p>
                            </div>
                            <div  className={styles.stats_right}>
                                    13:48
                            </div>
                        </div>
                        {/* time */}
                        <div className={styles.row}>
                            <div className={styles.stats_left}>
                                <img className={styles.stats_icon} src={icon_offline} alt="paused" />
                                <p className={styles.stats_label}>Оффлайн</p>
                            </div>
                            <div  className={styles.stats_right}>
                                    1
                            </div>
                        </div>
                        {/* time */}
                        <div className={styles.row}>
                            <div className={styles.stats_left}>
                                <img className={styles.stats_icon} src={icon_timer} alt="paused" />
                                <p className={styles.stats_label}>Опыт</p>
                            </div>
                            <div  className={styles.stats_right}>
                                    1
                            </div>
                        </div>
                    </div>
                </div>
                {/* info */}
                <div className={styles.info}>
                    <img src={icon_blue} />
                    <div className={styles.info_text}>
                        <p>Ваши предыдущие ответы сохранены.</p>
                        <p>Вы можете продолжить с того же вопроса.</p>
                    </div>
                    <img src={icon_blue} />

                </div>
                {/* buttons */}
                <div className={styles.buttons}>
                    <button onClick={handleExerciseStart} className={styles.btn_continue}>Продолжить практику</button>
                    <button onClick={closeModal} className={styles.btn_goBack}>Назад</button>
                </div>
            </div>
        </>
    )

    const RenderPreSuccess = ({ text }) => (
        <>
            <div>
                {text}
            </div>
        </>
    )


    const [countdown, setCountdown] = useState(3);
    useEffect(() => {
        if (isExerciseSuccess && countdown >= 0) {
        const timer = setTimeout(() => {
            setCountdown(prev => (prev === 0 ? -1 : prev - 1));
        }, 1000);
        return () => clearTimeout(timer);
        }
    }, [isExerciseSuccess, countdown]);

    const renderExerciseContent = () => {
        // start
        if (isExerciseUninitialized && dataTopicContent?.sessionStats?.endDate){
            return  <PopupExerciseFullScreen classNameLayout={styles.popup__layout_start}>
                        <RenderLayout />;
                    </PopupExerciseFullScreen>
        }
        // continue
        if (isExerciseUninitialized && !dataTopicContent?.sessionStats?.endDate){
            return  <PopupExerciseFullScreen classNameLayout={styles.popup__layout_continue}>
                        <ContinueSession />;
                    </PopupExerciseFullScreen>
        }
        // loading
        if (isExerciseLoading){
            return  <PopupExerciseFullScreen classNameLayout={styles.popup__layout_continue}>
                        <div>Loading...</div>;
                    </PopupExerciseFullScreen>
        }
        // countdown start
        if (isExerciseSuccess){
            return  <PopupExerciseFullScreen classNameLayout={styles.popup__layout_continue}>
                        {countdown > 0 ? <RenderPreSuccess text={countdown} /> : countdown === 0 ? <RenderPreSuccess text="Start" /> : <ExStandardKb dataExercise={dataExercise} />}
                    </PopupExerciseFullScreen>
        }
        if (isExerciseSuccess){
            return  <PopupExerciseFullScreen classNameLayout={styles.popup__layout_start}>
                        <ExStandardKb dataExercise={dataExercise} />;
                    </PopupExerciseFullScreen>
        }

        if (isExerciseError){
            return  <PopupExerciseFullScreen classNameLayout={styles.popup__layout_start}>
                        <div>Error: {errorExercise?.message || "An error occurred."}</div>;
                    </PopupExerciseFullScreen>
        }
        return null;
    };

    
    return (renderExerciseContent());
};

export default Modal_ExercisePre;
