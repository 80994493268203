import React, { useEffect, useRef, useState } from 'react';
import { MathJax } from 'better-react-mathjax';
import { useModalWindow } from "react-modal-global";
import useScrollBlock from './../../../../customHooks/useScrollBlock';
import PopupExerciseFullScreen from "./../../Layouts/PopupExerciseFullScreen";
import { setDefaultThemeColor } from './../../../../features/helmet/helmetSlice';
import { useDispatch } from 'react-redux';

import icon_coin from './../../../../assets/img/exercise/coin.svg';
import icon_exit from './../../../../assets/img/exercise/exit.svg';
import icon_timer from './../../../../assets/img/exercise/timer.svg';
import icon_xp from './../../../../assets/img/exercise/exp3.svg';

import { useCheckAnswerMutation } from './../../../../features/exercise/exerciseApiSlice';
import styles from './ExStandardKb.module.css';
import { triggerSessionStatsRequest } from '../../../../features/exercise/exerciseSlice';

const ExStandardKb = ({ dataExercise }) => {
  // Destructure sessionStats and sessionQuestions from the incoming data.
  const { sessionStats, sessionQuestions } = dataExercise || {};

  // Modal, scroll, and Redux hooks.
  const modal = useModalWindow();
  const [blockScroll, allowScroll] = useScrollBlock();
  const dispatch = useDispatch();
  const defaultThemeColor = () => dispatch(setDefaultThemeColor());

    const trigSessionStatsRequest = () => dispatch(triggerSessionStatsRequest());
  // RTK Query hook for checking answers.
  const [
    checkAnswer,
    { data: checkAnswerData, isLoading: isLoadingCheckAnswer, isSuccess: isSuccessCheckAnswer }
  ] = useCheckAnswerMutation();

  // Local state variables.
  const [userAnswer, setUserAnswer] = useState('');
  const [totalQuestions, setTotalQuestions] = useState(
    sessionStats ? sessionStats.totalQuestions - sessionStats.completedQuestions : 0
  );
  const [activeQuestion, setActiveQuestion] = useState(
    sessionQuestions && sessionQuestions.length > 0 ? sessionQuestions[0] : null
  );
  const [timerValue, setTimerValue] = useState(0); // Timer value in seconds.
  const [timerKey, setTimerKey] = useState(0); // Used to reinitialize countdown mode.
  const [questionResult, setQuestionResult] = useState(null); // 'correct' or 'incorrect'.
  const [timerPaused, setTimerPaused] = useState(false); // Pause/resume flag.
  const [sessionCompleted, setSessionCompleted] = useState(false); // Whether session is complete.

  // Refs to hold current values.
  const userAnswerRef = useRef(userAnswer);
  const activeQuestionRef = useRef(activeQuestion);
  const timerIntervalRef = useRef(null);
  const inputRef = useRef(null);

  // Keep refs in sync with state.
  useEffect(() => {
    userAnswerRef.current = userAnswer;
  }, [userAnswer]);

  useEffect(() => {
    activeQuestionRef.current = activeQuestion;
  }, [activeQuestion]);

  // Focus the input field on mount or when the active question changes.
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [modal.closed, activeQuestion]);

  // When sessionQuestions prop changes, update the active question.
  useEffect(() => {
    if (sessionQuestions && sessionQuestions.length > 0) {
      setActiveQuestion(sessionQuestions[0]);
      activeQuestionRef.current = sessionQuestions[0];
    }
  }, [sessionQuestions]);

  // --- Timer Effect ---
  // Handles both "timer" (countdown) and "stopWatch" (stopwatch) modes.
  useEffect(() => {
    if (!sessionStats) return;

    // Clear any existing timer interval.
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    }

    // Do not restart the timer if paused.
    if (timerPaused) return;

    const { timerType, timerDuration, startDate } = sessionStats;

    if (timerType === 'timer') {
      // Countdown mode: initialize with timerDuration or current timerValue.
      let remaining = timerValue > 0 ? timerValue : timerDuration;
      if (!timerValue && timerDuration) {
        setTimerValue(timerDuration);
        remaining = timerDuration;
      }
      timerIntervalRef.current = setInterval(() => {
        setTimerValue(prev => {
          // When time runs out...
          if (prev <= 1) {
            clearInterval(timerIntervalRef.current);
            timerIntervalRef.current = null;
            // Only auto-submit if the session is not completed.
            if (!sessionCompleted) {
              handleCheckAnswer();
            }
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else if (timerType === 'stopWatch') {
      // Stopwatch mode: initialize based on elapsed time on first run.
      if (timerKey === 0) {
        setTimerValue(Math.floor((Date.now() - new Date(startDate).getTime()) / 1000));
      }
      // Continue counting upward.
      timerIntervalRef.current = setInterval(() => {
        setTimerValue(prev => prev + 1);
      }, 1000);
    }

    // Cleanup interval on unmount or when dependencies change.
    return () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
        timerIntervalRef.current = null;
      }
    };
  }, [sessionStats, timerKey, timerPaused, sessionCompleted]);

  // --- Effect: Pause Timer When Session Is Completed ---
  useEffect(() => {
    if (sessionCompleted) {
      // Clear any running timer interval.
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
        timerIntervalRef.current = null;
      }
      // Set the timer to pause.
      setTimerPaused(true);
    }
  }, [sessionCompleted]);

  // --- Handle Answer Submission ---
  const handleCheckAnswer = () => {
    // For countdown mode, clear the timer interval before submitting.
    if (sessionStats && sessionStats.timerType === 'timer') {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
        timerIntervalRef.current = null;
      }
    }
    // In stopwatch mode, we let the timer continue running.
    const currentQuestion = activeQuestionRef.current;
    if (!currentQuestion) {
      console.error("Current question record is lost!");
      return;
    }
    // Prepare payload for the checkAnswer API.
    const answersArray = [
      { answer: userAnswerRef.current, questionId: currentQuestion.questionId }
    ];
    const inputData = { answers: answersArray, sessionId: sessionStats?._id };
    checkAnswer(inputData);
  };

  // Toggle pause/resume for the timer with the space bar.
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.code === "Space") {
        e.preventDefault();
        setTimerPaused(prev => !prev);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  // Handle Enter key to submit the answer.
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleCheckAnswer();
    }
  };

  // --- Process API Response from checkAnswer ---
  useEffect(() => {
    if (isSuccessCheckAnswer && checkAnswerData) {
      // If the API response indicates the session status is "done", mark session as complete.
      if (checkAnswerData.status === 'done') {
        setSessionCompleted(true);
      } else {
        setSessionCompleted(false);
      }

      // Update the number of questions left.
      setTotalQuestions(
        checkAnswerData.sessionStats.totalQuestions - checkAnswerData.sessionStats.completedQuestions
      );

      // Provide feedback: 'correct' or 'incorrect'.
      const isCorrect = checkAnswerData.response.isCorrect;
      setQuestionResult(isCorrect ? 'correct' : 'incorrect');

      // Update the active question if a new one is provided (and session is ongoing).
      if (!sessionCompleted && checkAnswerData.sessionQuestions && checkAnswerData.sessionQuestions.length > 0) {
        const newQuestion = checkAnswerData.sessionQuestions[0];
        setActiveQuestion(newQuestion);
        activeQuestionRef.current = newQuestion;
      }

      // Clear the user answer.
      setUserAnswer('');

      // After a short delay, update timer and clear feedback.
      setTimeout(() => {
        // For countdown mode, reset the timer.
        if (checkAnswerData.sessionStats.timerType === 'timer') {
          setTimerValue(checkAnswerData.sessionStats.timerDuration);
          setTimerKey(prev => prev + 1);
        }
        // For stopwatch mode, do not reset the timer.
        setQuestionResult(null);
        setTimerPaused(false);
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 300);
    }
  }, [isSuccessCheckAnswer, checkAnswerData, sessionCompleted]);

  // --- Reset and Exit Handlers ---
  const resetExercise = (e) => {
    e.preventDefault();
    console.log('reset');
    // Implement additional reset logic as needed.
  };

  const exitExercise = (e) => {
    e.preventDefault();
    trigSessionStatsRequest()
    console.log('exit');
    closeModal();
  };

  const closeModal = () => {
    defaultThemeColor();
    modal.close();
  };

  // --- Completed Layout ---
  // Render a different UI when the session is complete.
  if (sessionCompleted && checkAnswerData) {
//   if (true) {
    return (
      <PopupExerciseFullScreen classNameLayout={styles.popup__layout}>
        <div className={styles.completedContainer}>
          <h2>Exercise Completed</h2>
          <div className={styles.statsSummary}>
            <p>Total Questions: {checkAnswerData?.sessionStats.totalQuestions}</p>
            <p>Completed: {checkAnswerData?.sessionStats.completedQuestions}</p>
            <p>Correct Answers: {checkAnswerData?.sessionStats.correctAnswers}</p>
            <p>
              Time Spent: {Math.floor(checkAnswerData?.sessionStats.timeSpent / 60)}:
              {("0" + (checkAnswerData?.sessionStats.timeSpent % 60)).slice(-2)}
            </p>
          </div>
          <button onClick={exitExercise} className={styles.btn_exit}>
            <img src={icon_exit} alt="exit" /> Exit
          </button>
        </div>
      </PopupExerciseFullScreen>
    );
  }

  // --- Ongoing Exercise Layout ---
  // Render the exercise UI when the session is ongoing.
  const renderQuestion = () => {
    if (!activeQuestion) return null;
    // Apply feedback styling.
    const questionClass = `${styles.exerciseExpression} ${
      questionResult === 'correct'
        ? styles.correct
        : questionResult === 'incorrect'
        ? styles.incorrect
        : ''
    }`;

    // Use MathJax rendering if required.
    const content = activeQuestion.hasMathJax ? (
      
        <p className={questionClass}>
          <MathJax>
            {`$$${activeQuestion.questionText}$$`}
          </MathJax>
        </p>
      
    ) : (
      <p className={questionClass}>{activeQuestion.questionText}</p>
    );
    return content;
  };

  return (
    <PopupExerciseFullScreen classNameLayout={styles.popup__layout}>
      {/* Header Section */}
      <div className={styles.header_container_1}>
        <div className={styles.container_1_left}>
          <p></p>
        </div>
        <div className={styles.container_1_right}>
          <button className={styles.btn_exit} type="button" onClick={exitExercise}>
            <img src={icon_exit} alt="exit" /> Exit
          </button>
        </div>
      </div>

      {/* Timer & Stats Header */}
      <div className={styles.header_container_2}>
        <div className={styles.container_2_left}>
          <span className={styles.questionsLeftTitle}>Left:</span>
          <span className={styles.numberOfQuestionsLeft}>{totalQuestions}</span>
        </div>
        {/* Timer display (click to toggle pause/resume) */}
        <div
          className={styles.container_2_middle}
          onClick={() => setTimerPaused(prev => !prev)}
          style={{ cursor: 'pointer' }}
        >
          <img src={icon_timer} alt="timer" />
          <span className={styles.timer}>
            {("0" + Math.floor(timerValue / 60)).slice(-2)}:
            {("0" + (timerValue % 60)).slice(-2)}
          </span>
        </div>
        <div className={styles.container_2_right}>
          <div className={styles.stats_wrapper}>
            <img src={icon_xp} alt="xp" />
            <span>20</span>
          </div>
          <div className={styles.stats_wrapper}>
            <img src={icon_coin} alt="coin" />
            <span>1500</span>
          </div>
        </div>
      </div>

      {/* Main Content: Active Question */}
      <div className={styles.main_content}>
        <p className={styles.exerciseTask}>Calculate:</p>
        {renderQuestion()}
      </div>

      {/* Input Field for User Answer */}
      <div className={styles.bottom}>
        <input
          className={styles.problem_field}
          ref={inputRef}
          type="text"
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </div>
    </PopupExerciseFullScreen>
  );
};

export default React.memo(ExStandardKb);