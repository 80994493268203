import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";


const exerciseAdapter = createEntityAdapter({})

const initialState = exerciseAdapter.getInitialState()
const prePath = '/library'
export const exerciseApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        startExercise: builder.mutation({
          query: initialOrder => ({
              url: `${prePath}/exercise`,
              method: 'POST',
              body: {
                  ...initialOrder,
              },
          }),
    
        }),
        checkAnswer: builder.mutation({
          query: initialOrder => ({
              url: `${prePath}/exercise/checkAnswer`,
              method: 'POST',
              body: {
                  ...initialOrder,
              },
          }),
    
        })
        
    })
})

export const {
  useStartExerciseMutation,
  useCheckAnswerMutation
} = exerciseApiSlice