import React from "react";
import { useSelector } from "react-redux";
import { selectUserId } from "../../../../../features/auth/authSlice";
import styles from "./ChatListItem.module.css";
// images
import icon_air from "./../../../../../assets/img/tribe/airCoin.svg";
import icon_earth from "./../../../../../assets/img/tribe/earthCoin.svg";
import icon_fire from "./../../../../../assets/img/tribe/fireCoin.svg";
import icon_water from "./../../../../../assets/img/tribe/waterCoin.svg";

// Map tribe names to icons
const tribeIconMap = {
  air: icon_air,
  earth: icon_earth,
  fire: icon_fire,
  water: icon_water,
};

const ChatListItem = ({ chat, isSearchResult = false, onClick }) => {
  const currentUserId = useSelector(selectUserId);

  const onlineUsers = useSelector((state) => state.chat.onlineUsers);

  // For chats, get the other participant; for search results, chat is a user object.
  const otherUser = chat.participants
    ? chat.participants.find((user) => user._id !== currentUserId) || {}
    : chat;

  const isOnline = Boolean(otherUser._id && onlineUsers && onlineUsers.includes(otherUser._id));

  // For search results, use "about" text; otherwise, show last message text.
  const displayText = isSearchResult
    ? otherUser.about || "No about available"
    : chat.lastMessage?.text
    ? chat.lastMessage.text.length > 29
      ? chat.lastMessage.text.substring(0, 29) + "..."
      : chat.lastMessage.text
    : "No messages yet";

  const tribeIcon = tribeIconMap[otherUser.tribe?.toLowerCase()];

  return (
    <li
      className={styles.user_chat}
      onClick={() => onClick && onClick(chat, isSearchResult)}
    >
      <div className={styles.chatAvatar}>
        <span className={styles.initials}>
          {otherUser.first_name?.[0] || "N"}
          {otherUser.last_name?.[0] || "N"}
        </span>
        <span className={`${styles.status} ${isOnline ? styles.online : styles.offline}`}>
            {
              isOnline ? (
                <>
                  <svg width="4" height="4" viewBox="0 0 4 4" fill="none">
                    <circle cx="2" cy="2" r="2" fill="#3B9C30" />
                  </svg>
                  Online
                </>
              ) : (
                <>
                  <svg width="5" height="4" viewBox="0 0 5 4" fill="none">
                    <circle cx="2.5" cy="2" r="2" fill="#DC2D16" />
                  </svg>
                  Offline
                </>
              )
            }
        </span>
      </div>
      <div className={styles.chatDetails}>
        <div className={styles.directOrGroupName_block}>
          <img src={tribeIcon} alt="Tribe" className={styles.tribeImage} />
          <p className={styles.senderName}>
            {isSearchResult
              ? `${otherUser.first_name} ${otherUser.last_name}`
              : chat.lastMessage?.senderId === currentUserId
              ? "You"
              : `${otherUser.first_name || "Unknown"} ${otherUser.last_name || ""}`}
          </p>
        </div>
        <div className={styles.messageInfo}>
          <p className={styles.lastMessage}>{displayText}</p>
        </div>
      </div>
      {!isSearchResult && chat.newMessagesCount > 0 && (
        <div className={styles.newMessagesCount}>
          <span>+{chat.newMessagesCount}</span>
        </div>
      )}
    </li>
  );
};

export default ChatListItem;