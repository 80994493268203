import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import useAuth from '../customHooks/useAuth';
import { selectCurrentToken } from '../features/auth/authSlice';
import { useSelector } from 'react-redux';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const token = useSelector(selectCurrentToken)
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (token) {
            // Initialize socket when user is available
            const newSocket = io(process.env.REACT_APP_BASE_URL, {
                auth: { token: token },
            });
            setSocket(newSocket);

            // Cleanup socket on unmount or user change
            return () => {
                newSocket.disconnect();
                setSocket(null);
            };
        }
    }, [token]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
