// create new chat when received messages

import React, { useEffect, useRef, useState } from "react";
import styles from "./ConversationView.module.css";
// redux
import { useDispatch, useSelector } from "react-redux";
import { setMessages, addMessage, resetSelectChat, setSelectChat, addChat } from "../../../../../features/chat/chatSlice";
// RTK Query hook
import { useGetMessagesByChatIdQuery } from "../../../../../features/chat/chatApiSlice";
import { selectUserId } from "../../../../../features/auth/authSlice";
// socket
import { useSocket } from "../../../../../app/SocketContext";

const ConversationView = () => {
    const dispatch = useDispatch();
    const selectedChat = useSelector((state) => state.chat.selectedChat);
    const currentUserId = useSelector(selectUserId);
    console.log(selectedChat)

    const chatMessages = useSelector((state) => state.chat.messages);
    const messagesContainerRef = useRef(null);
    const [messageText, setMessageText] = useState("");
    const socket = useSocket();
    const handleSendMessage = () => {
        if (!messageText.trim()) return;
      
        // Prepare the new message.
        const newMsg = {
            _id: Date.now().toString(), // temporary id
            chatId: selectedChat._id,
            text: messageText,
            senderId: { _id: currentUserId },
            createdAt: new Date().toISOString(),
        };
        if (selectedChat.isNew) {
            newMsg.participants = selectedChat.participants;
        }
      
        // Emit the message with an acknowledgment callback.
        socket.emit("sendMessage_toServer", newMsg, (err, response) => {
            if (err) {
                console.error(err);
                dispatch(addMessage({ ...newMsg, error: true }));
            } else if (response && response.message) {
              const message = response.message

              if (selectedChat.isNew) {
                  // Update the selectedChat with the new chat id, remove isNew, and update lastMessage.
                  const updatedChat = {
                                          ...selectedChat,
                                          _id: message.chatId, // new chat id returned from server
                                          isNew: false,
                                          lastMessage: message.text,
                                          updatedAt: message.updatedAt,
                                      };
                  dispatch(setSelectChat(updatedChat));
                  dispatch(addChat(updatedChat));
                }
                dispatch(addMessage(response.message));
            }
        });
      
        setMessageText("");
    };


    // Always fetch messages whenever a chat is selected.
    const { data: messages, isSuccess, isLoading, isError } =
      useGetMessagesByChatIdQuery(selectedChat?._id, {
        refetchOnMountOrArgChange: true,
        skip: !selectedChat || selectedChat.isNew,
    });

    useEffect(() => {
        if (isSuccess && messages) {
          dispatch(setMessages({ chatId: selectedChat._id, messages: messages }));
        }
    }, [isSuccess, messages, dispatch, selectedChat]);

    // Auto scroll down only when the chat is opened.
    useEffect(() => {
      if (selectedChat && messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
      }
    }, [selectedChat]);

    // Determine the other participant.
    const otherParticipant =
      selectedChat?.participants.find(
        (p) => p._id !== selectedChat.currentUserId
      ) || {};

    if (!selectedChat) {
      return <div>Select a chat to view messages.</div>;
    }

    return (
      <div className={styles.conversationContainer}>
        <header className={styles.header}>
          <button
            onClick={() => dispatch(resetSelectChat())}
            className={styles.backButton}
          >
            Back
          </button>
          <h2 className={styles.chatName}>
            {otherParticipant.first_name} {otherParticipant.last_name}
          </h2>
        </header>
        <div className={styles.messagesContainer} ref={messagesContainerRef}>
          {isLoading && <p>Loading messages...</p>}
          {isError && <p>Error loading messages.</p>}
          {isSuccess && (chatMessages[selectedChat._id] || []).map((msg) => {
            return (
                      <div
                        key={msg._id}
                        className={
                          msg.senderId._id === currentUserId
                            ? styles.myMessage
                            : styles.otherMessage
                        }
                      >
                        <p>
                            {msg.text} {msg.error && (<span className={styles.errorLabel}>Error</span>)}
                        </p>

                        <span className={styles.messageTimestamp}>
                          {new Date(msg.createdAt).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      </div>
                    )
            })}
        </div>

        <footer className={styles.inputArea}>
          <input
            type="text"
            placeholder="Type a message..."
            className={styles.input}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
          <button onClick={handleSendMessage} className={styles.sendButton}>Send</button>
        </footer>
      </div>
    );
};

export default ConversationView;