import styles from './SubjectLayout.module.css'
// Components
import SBreadcrumbs from '../common/SBreadcrumbs'
import TopicLayout from './TopicLayout';


// const Chapter = () => {
//     return  <>
//                 <Link to='/library/math/lesson01' className={styles.chapter}>
//                     <span className={styles.lesson}>01 - Timestable - Multiplication - Level 1</span>
//                 </Link>
//                 <Link to='/library/math/lesson02' className={styles.chapter}>
//                     <span className={styles.lesson}>02 - Timestable - Multiplication - Level 2</span>
//                 </Link>
//                 <Link to='/library/math/lesson03' className={styles.chapter}>
//                     <span className={styles.lesson}>03 - Timestable - Multiplication - Level 3</span>
//                 </Link>
//                 <Link to='/library/math/lesson04' className={styles.chapter}>
//                     <span className={styles.lesson}>04 - Timestable - Multiplication - Level 4</span>
//                 </Link>

//                 <Link to='/library/math/lesson05' className={styles.chapter}>
//                     <span className={styles.lesson}>05 - Timestable - Division - Level 1</span>
//                 </Link>
//                 <Link to='/library/math/lesson06' className={styles.chapter}>
//                     <span className={styles.lesson}>06 - Timestable - Division - Level 2</span>
//                 </Link>
//                 <Link to='/library/math/lesson07' className={styles.chapter}>
//                     <span className={styles.lesson}>07 - Timestable - Division - Level 3</span>
//                 </Link>
//                 <Link to='/library/math/lesson08' className={styles.chapter}>
//                     <span className={styles.lesson}>08 - Timestable - Division - Level 4</span>
//                 </Link>

//                 <Link to='/library/math/lesson09' className={styles.chapter}>
//                     <span className={styles.lesson}>09 - Addition - Level 1</span>
//                 </Link>
//                 <Link to='/library/math/lesson10' className={styles.chapter}>
//                     <span className={styles.lesson}>10 - Addition - Level 2</span>
//                 </Link>
//                 <Link to='/library/math/lesson11' className={styles.chapter}>
//                     <span className={styles.lesson}>11 - Subtraction - Level 1</span>
//                 </Link>
//                 <Link to='/library/math/lesson12' className={styles.chapter}>
//                     <span className={styles.lesson}>12 - Subtraction - Level 2</span>
//                 </Link>
//                 <Link to='/library/math/lesson13' className={styles.chapter}>
//                     <span className={styles.lesson}>13 - Mixed - Level 1</span>
//                 </Link>
//                 <Link to='/library/sardar/code' className={styles.chapter}>
//                     <span className={styles.lesson}>Sardar Code</span>
//                 </Link>
//                 <Link to='/library/sardar/exercise' className={styles.chapter}>
//                     <span className={styles.lesson}>Sardar Exercise</span>
//                 </Link>
//             </>
// }

const SubjectLayout = () => {

    return (
        <>
            <SBreadcrumbs returnTo="/library" />
            <div className={styles.section}>
                    <TopicLayout />

            </div>
        </>
    )
}

export default SubjectLayout