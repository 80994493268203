import styles from './Library.module.css'

import imgSubject from './../student/assets/subject.png'
import { Link } from 'react-router-dom'

const Library = () => {
  return (
    <section className={styles.library}>
        <div className={styles.wrapper}>
            <h2 className={styles.header}>Точные науки</h2>
            <div className={styles.row}>
                <div className={styles.card}>
                    <img src={imgSubject} alt="card" />
                    <h3 className={styles.card_header}>Алгебра</h3>
                    <Link to="/library/maths" className={styles.btn}>Начать</Link>
                </div>
            </div>
        </div>

        <div className={styles.wrapper}>
            <h2 className={styles.header}>Гуманитарные науки</h2>
            <div className={styles.row}>
                <div className={styles.card}>
                    <img src={imgSubject} alt="card" />
                    <h3 className={styles.card_header}>Английский</h3>
                    <Link to="/library/english" className={styles.btn}>Начать</Link>
                </div>
            </div>
        </div>
    </section>
)}

export default Library