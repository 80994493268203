import styles from './TopicContent.module.css'
import React, { useEffect } from 'react'
import Markdown from 'markdown-to-jsx'
import { MathJax } from 'better-react-mathjax'
// Images
import InlineSVG from 'react-inlinesvg'
import icon_arrowLeft from "./../../../assets/img/library/breadcrumbs/arrow_left.svg"

import icon_numberOfSessions from "./../../../assets/img/library/topic/numberOfSessions.svg" 
import icon_totalTime from "./../../../assets/img/library/topic/totalTime.svg" 
import icon_numberOfDistractions from "./../../../assets/img/library/topic/numberOfDistractions.svg" 
import icon_progress from "./../../../assets/img/library/topic/progress.svg" 
// Redux
import { useDispatch } from 'react-redux'
// Modal
import useScrollBlock from '../../../customHooks/useScrollBlock'
import Modal_1 from '../../Modals/Modal_1'
import Modal_ExercisePre from '../../Modals/Exercises/ExercisePre/Modal_ExercisePre'
  

const CustomBlockquote = ({ children, ...props }) => {
    let isFirstParagraph = true; // Tracks the first paragraph
    const nonFirstParagraphs = []; // Collects non-first paragraphs

    // Iterate through children to organize content
    React.Children.forEach(children, (child) => {
        if (child.type === "p") {
            if (!isFirstParagraph) {
                nonFirstParagraphs.push(
                    React.cloneElement(child, { className: "3" }) // Apply class "3" to non-first paragraphs
                );
            } else {
                isFirstParagraph = false; // Mark the first paragraph as processed
            }
        }
    });

    return (
        <blockquote className={styles.example}>
            {/* Render the first paragraph with class "2" */}
            {React.Children.map(children, (child, index) => {
                if (index === 0 && child.type === "p") {
                    return React.cloneElement(child, { className: "2" });
                }
                return null; // Skip for the first pass
            })}

            {/* Render the rest of the paragraphs with class "3" */}
            {nonFirstParagraphs}
        </blockquote>
    );
};
  
  
const markdownContent = `

`
const RightProgressInfo = ({ dataTopicContent }) => {
    return  <>
                <div className={styles.right}>
                    <div className={styles.row}>
                        <p className={styles.progressTitle}>Общий прогресс</p>
                        <button className={styles.btnProgress}>
                                подробнее
                                <InlineSVG className={styles.arrowRight} src={icon_arrowLeft} />
                        </button>
                    </div>
                    <div className={styles.row}>

                        <div className={styles.block_1}>
                            <div className={styles.progress_row}>
                                <InlineSVG src={icon_numberOfSessions} />
                                <p>Сессий</p>
                            </div>
                            <span className={styles.progress_value}>
                                { dataTopicContent?.stats?.temp ?? 0 }
                            </span>
                        </div>

                        <div className={styles.block_2}>
                            <div className={styles.progress_row}>
                                <InlineSVG src={icon_numberOfSessions} />
                                <p>Время</p>
                            </div>
                            <span className={styles.progress_value}>
                                { dataTopicContent?.stats?.temp ?? 0 }
                            </span>
                        </div>

                        <div className={styles.block_3}>
                            <div className={styles.progress_row}>
                                <InlineSVG src={icon_numberOfSessions} />
                                <p>Пауз</p>
                            </div>
                            <span className={styles.progress_value}>
                                { dataTopicContent?.stats?.temp ?? 0 }
                            </span>
                        </div>

                        <div className={styles.block_4}>
                            <div className={styles.progress_row}>
                                <InlineSVG src={icon_numberOfSessions} />
                                <p>Изучено</p>
                            </div>
                            <span className={styles.progress_value}>
                                { dataTopicContent?.stats?.temp ?? 0 }
                            </span>
                        </div>
                    </div>
                </div>
            </>
}
const TopicContent = ({ dataTopicContent }) => {
    // console.log(dataTopicContent)
    const dispatch = useDispatch();
    const [blockScroll, allowScroll] = useScrollBlock();
    
    // Modal_offCanvas_Left
    const open_Modal_ExercisePre = () => {
        // setTimeout(function(){}, 150);
        // changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_1.open(Modal_ExercisePre, { dataTopicContent: dataTopicContent });
    }

    const sections = dataTopicContent?.content ? dataTopicContent.content.split('<!-- section break -->').map(section => section.trim()) : null;
    // const sections = markdownContent.split('<!-- section break -->').map(section => section.trim())
    const progressPercentage = dataTopicContent?.exerciseStats 
                                                ?   (
                                                        (!dataTopicContent?.exerciseStats?.isDone && dataTopicContent?.exerciseStats?.completedQuestions == dataTopicContent?.exerciseStats?.totalQuestions)
                                                            ? 99
                                                            : Math.floor((dataTopicContent?.exerciseStats?.completedQuestions / dataTopicContent?.exerciseStats?.totalQuestions) * 100) 
                                                    )
                                                : 0;
    return (
        <section className={styles.wrapper}>
            
            {dataTopicContent?.content 
                ?   <>
                        <div className={styles.header}>
                            <div className={styles.left}>
                                <h1 className={styles.title}>{dataTopicContent?.name}</h1>
                                {
                                    dataTopicContent?.hasExercise && (
                                        <button onClick={open_Modal_ExercisePre} className={styles.btnStart}>
                                            {!dataTopicContent?.exerciseStats
                                                ? "Начать (0%)"
                                                : dataTopicContent?.exerciseStats?.isDone
                                                    ? "Начать заново (100%)"
                                                    : `Продолжить (${progressPercentage}%)`
                                            }
                                            
                                        </button>
                                    )
                                }

                                
                            </div>
                            {
                                dataTopicContent?.hasExercise &&
                                <RightProgressInfo dataTopicContent={dataTopicContent} />
                            }
                        </div>
                        
                        <MathJax dynamic hideUntilTypeset="every" key={dataTopicContent?._id}>
                            {sections.map((section, index) => (
                                <Markdown
                                    key={`${dataTopicContent?._id}-${index}`} // Unique key for each section
                                    className={styles.markdown}
                                    options={{
                                        overrides: {
                                            blockquote: {
                                                component: CustomBlockquote,
                                            },
                                        },
                                    }}
                                >
                                    {section}
                                </Markdown>
                            ))}
                        </MathJax>
                        
                    </>
                : "No content"
            }
        </section>
    )
}

export default TopicContent