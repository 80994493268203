import { Routes, Route } from "react-router-dom";
// import VLayout from "./components/visitor/VLayout";
// import ALayout from "./components/admin/ALayout";

// users
// import ALogin from "./components/admin/Login/ALogin";
// import ADashboard from "./components/admin/Dashboard/ADashboard"

// MODAL
import { Suspense, useEffect, useState} from "react";
import { ModalContainer } from "react-modal-global";
import Modal_1 from "./components/Modals/Modal_1";
import Modal_2 from "./components/Modals/Modal_2";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// Protection
import PersistLogin from "./features/auth/PersistLogin"
import RequireAuth from "./features/auth/RequireAuth"
import { ROLES } from "./config/roles";
// RTK Query
import { useGetDetailsMutation } from "./features/details/detailsApiSlice";
// IP
import { setAppVersion } from "./features/details/detailsSlice";
// Pages
import PageNotFound404 from './components/visitor/common/PageNotFound404'
import VMain from './components/visitor/pages/VMain/VMain'

import ExCountdownLayout from "./components/student/ExLayouts/Countdown/ExCountdownLayout";
// MathJax
import { MathJaxContext } from "better-react-mathjax";
import SubjectLayout from "./components/student/library/SubjectLayout";
import English from "./components/student/library/english/English";

// Student
import SLayout from "./components/student/SLayout";
import SLayout_2_w_offcanvas from "./components/student/SLayout_2_w_offcanvas";
import SLayout_2_wo_offcanvas from "./components/student/SLayout_2_wo_offcanvas";

import Library from "./components/student/Library";
// Teacher
import StudentDetails from "./components/teacher/students/StudentDetails";

// Socket IO
// import { SocketProvider } from "./app/SocketContext";

// Sardar
import SardarCode from "./components/student/sardar/SardarCode";
import SardarExercise from "./components/student/sardar/SardarExercise";

// BATTLES
import VBattles from "./components/visitor/battles/VBattles";
// device
import useDeviceType from './features/device/useDeviceType'

function App() {
    useDeviceType()
    const dispatch = useDispatch();

    const [getDetails, {
        data: details,
        isLoading, 
        isSuccess, 
        // isUninitialized,
        // isError, 
        // error
    }] = useGetDetailsMutation()
    
    const appVersion = useSelector(state => state.details.appVersion);
    
    useEffect( () => {  
        // Details
        getDetails()
        // force reload window
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
              getDetails()
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [getDetails])

    const [showedOnce, setShowedOnce] = useState(false)
    useEffect(() => {
      if (isSuccess && details) {
          // details
          if(details.appVersion !== appVersion){
              dispatch(setAppVersion(details.appVersion))
              window.location.reload();
          }
          if(!showedOnce){
            setShowedOnce(true)
          }
      }
    }, [isSuccess, details, appVersion, dispatch, showedOnce])

    const [minimumLoadTimePassed, setMinimumLoadTimePassed] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setMinimumLoadTimePassed(true);
        }, 300); // Set minimum display time for preloader

        return () => clearTimeout(timer);
    }, []);

    
    if(isLoading || !minimumLoadTimePassed){
      if(!showedOnce || !minimumLoadTimePassed){
        // return <Preloader />
        return <span>loading</span>
      }
    }
    
    const config = {
        options: {
            enableMenu: false // Disables the context menu
        },
        tex: {
            packages: { "[+]": ["html"] },
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]],
        },
    };
    return (
      <>
      {/* <MathJaxContext config={config} src={"https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js"}> */}
      <MathJaxContext config={config} version={3} src={"/assets/mathjax/tex-svg.js"}>
         
            <Routes>
              {/* <Route path="/" element={<VLayout />}> */}
              <Route path="/">
                  <Route index element={<VMain />} />
                  
                    <Route path="/battles">
                        <Route index element={<VBattles />} />
                    </Route>

              </Route>

              <Route element={<PersistLogin ifUnauthorisedRedirectTo='/' />}>
                    

                        <Route element={<SLayout />} >
                                <Route element={<RequireAuth allowedRoles={[ ROLES.teacher ]} ifNotAllowedNavigateTo='/admin' />}>
                                        <Route path="/students/:id" element={<StudentDetails />} />
                                </Route>

                                <Route path="dashboard" element={<SLayout_2_wo_offcanvas />}>
                                    <Route index element={<Library />} />
                                </Route>
                                <Route path="library" element={<SLayout_2_wo_offcanvas />}>
                                    <Route index element={<Library />} />
                                </Route>
                                
                                <Route path="students/english" element={<SLayout_2_w_offcanvas />} >
                                        <Route index element={<English />} />
                                </Route>

                                <Route element={<RequireAuth allowedRoles={[ ROLES.teacher, ROLES.admin, ROLES.superAdmin, ROLES.owner]} ifNotAllowedNavigateTo='/library' />}>

                                    <Route path="library/:subject/:topic?" element={<SLayout_2_w_offcanvas />}>
                                        <Route index element={<SubjectLayout />} />
                                    </Route>

                                    
                                    
                                    


                                    <Route path="sardar">
                                            <Route path="code" element={<SardarCode />} />
                                            <Route path="exercise" element={<SardarExercise />} />
                                    </Route>
                                    

                                </Route>
                        </Route> 


              </Route> 

              <Route path="*" element={<PageNotFound404 />} />
                {/* admin */}
                {/* <Route path="admin"> */}
                    {/* DASHBOARD */}
                    {/* <Route index element={<ALogin />} /> */}
                    {/* Protected Routes */}
                    {/* <Route element={<PersistLogin ifUnauthorisedRedirectTo='/admin' />}>
                        <Route element={<ALayout />} >
                            <Route element={<RequireAuth allowedRoles={[ ROLES.Nurse, ROLES.Doctor, ROLES.Admin, ROLES.SuperAdmin, ROLES.Owner]} ifNotAllowedNavigateTo='/admin' />}>
                                <Route path="dashboard" element={<ADashboard/>} />
                              
                            </Route>
                        </Route>
                    </Route>


                </Route> */}
                
            </Routes>
        <Suspense>
            <ModalContainer controller={Modal_1} />
            <ModalContainer controller={Modal_2} />
        </Suspense>
      
      </MathJaxContext>
      </>
    
  );
}

export default App;
