import { useState } from "react";
import styles from "./CustomSwitch.module.css"; // Importing module CSS

const CustomSwitch = ({ onChange, defaultChecked = false }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleToggle = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    onChange(newValue);
  };

  return (
    <div className={styles.switchContainer}>
        {/* {label && <span className={styles.switchLabel}>{label}</span>} */}
        <div
            className={`${styles.switch} ${isChecked ? styles.checked : ""}`}
            onClick={handleToggle}
        >
            <div className={styles.switchThumb}></div>
        </div>
    </div>
  );
};

export default CustomSwitch;