import styles from './SLayout_2_wo_offcanvas.module.css'

import { Outlet } from 'react-router-dom'
import SNav from './common/SNav'
import { setIsLeftOffcanvasCollapsed } from '../../features/library/librarySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const SLayout_2_wo_canvas = () => {
    const dispatch = useDispatch()
    const isLeftOffcanvasCollapsed = useSelector(state => state.library.isLeftOffcanvasCollapsed);
    useEffect(()=>{
        dispatch(setIsLeftOffcanvasCollapsed(true))
    },[])

    const content = (
        <>
            <SNav isLeftOffcanvasCollapsed={isLeftOffcanvasCollapsed} />
            <Outlet />
            {/* <SFooter /> */}
            
            {/* <div className={styles.main}>
                <p className={styles.text}>Server Time: {formatTime(serverTime)}</p>
            
                <p className={styles.text}>Author: Marat Shakeshev [Version 1.0.234.5678]</p>
                <p className={styles.text}>(c) ArcTeach. All rights reserved.</p>
                
                <p className={`${styles.text} ${styles.caret}`}>
                // C:\{`>`}
                (admin) shakeshev@Marats-MacBook-Pro ~ % 
                </p>  
            </div>   */}
        </>
    )

    return content
}

export default SLayout_2_wo_canvas