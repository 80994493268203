import styles from './TopicLayout.module.css'
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
// Redux
import { useLazyGetTopicContentByTopicIdQuery } from '../../../features/library/libraryApiSlice'
// Components
import TopicContent from './TopicContent';
import { useDispatch, useSelector } from 'react-redux';
import { resetSessionStatsRequest, selectShouldTriggerSessionStatsRequest, setTopicData } from '../../../features/exercise/exerciseSlice';



const TopicLayout = () => {
    const dispatch = useDispatch();
    const saveTopicData = (data) => dispatch(setTopicData(data));
    const triggerResetSessionStats = () => dispatch(resetSessionStatsRequest());
    const shouldTriggerSessionStatsRequest = useSelector(selectShouldTriggerSessionStatsRequest);

    const { subject, topic } = useParams();
    
    const [
        getTopicContentByTopicId,
       {
            data: dataTopicContent,
            isLoading: isLoadingGetTopicContent,
            isSuccess: isSuccessGetTopicContent,
            isError: isErrorGetTopicContent,
            error: errorGetTopicContent
        },
    ] = useLazyGetTopicContentByTopicIdQuery();
 
    // Trigger API call when 'topic' changes
    useEffect(() => {
        if (topic) {
            getTopicContentByTopicId(topic);
        }
    }, [topic, getTopicContentByTopicId]);

    // refetch to update page
    useEffect(()=> {
        if(shouldTriggerSessionStatsRequest && topic){
            getTopicContentByTopicId(topic);
        }
    },[shouldTriggerSessionStatsRequest])

    useEffect(()=> {
        if(isSuccessGetTopicContent){
            saveTopicData(dataTopicContent)    
            // reset redux so i can refetch again after close exercise modal
            triggerResetSessionStats()
        }
    },[dataTopicContent])

    return (
      <>
          {/* Logging and conditional rendering */}
          {(() => {
              if (isLoadingGetTopicContent) {
                  return <p>Loading topic content...</p>; // Show a loading message
              }

              if (isErrorGetTopicContent) {
                  return <p>Error fetching topic content. Please try again later.</p>; // Show an error message
              }

              if (isSuccessGetTopicContent) {     
                    return <TopicContent dataTopicContent={dataTopicContent} />; // Render content
              }

              return null; // Return nothing if no condition matches
          })()}
      </>
    )
}

export default TopicLayout