import styles from './RadioGroup.module.css'
import { useEffect, useState } from "react";

const RadioGroup = ({ options, groupName, onChange, customClass, defaultValue }) => {
  const [selected, setSelected] = useState(defaultValue || options[1]?.value);

  const handleChange = (value) => {
    setSelected(value);
    onChange(value);
  };

  useEffect( () => {
    onChange(defaultValue || options[1]?.value)
  },[])

  return (
      <div className={styles.radio_options}>
        {options.map(({ value, content }) => (
          <label
            key={value}
            className={`${styles.radio_option} ${selected === value ? styles.selected : ""}`}
            onClick={() => handleChange(value)}
          >
            <input
              type="radio"
              name={groupName}
              value={value}
              checked={selected === value}
              onChange={() => handleChange(value)}
              className={styles.hidden_radio}
            />
            {content} {/* This now accepts a full component */}
          </label>
        ))}
      </div>
  );
};

export default RadioGroup;