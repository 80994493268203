import styles from './SLayout_2_w_offcanvas.module.css'
import { Outlet, useParams } from 'react-router-dom'

import SNav from './common/SNav'
import LibraryContent from './library/LibraryContent'
import { useDispatch, useSelector } from 'react-redux';
// Redux
import { useGetSubjectContentBySubjectNameQuery } from '../../features/library/libraryApiSlice'
import { setIsLeftOffcanvasCollapsed } from "./../../features/library/librarySlice";
import { useEffect } from 'react';


const SLayout_2_w_offcanvas = () => {
    const dispatch = useDispatch()
    const isLeftOffcanvasCollapsed = useSelector(state => state.library.isLeftOffcanvasCollapsed);
    useEffect(()=>{
        dispatch(setIsLeftOffcanvasCollapsed(false))
    },[])
    const { subject } = useParams();  // Extract `id` from URL

    const {
        data: dataSubjectContent,
        isLoading: isLoadingGetSubjectContent, 
        isSuccess: isSuccessGetSubjectContent,
        isError: isErrorGetSubjectContent,
        error: errorGetSubjectContent,
        refetch: refetchSubjectContent
    } = useGetSubjectContentBySubjectNameQuery(subject); // Automatically calls the API

    const dataChapters = isSuccessGetSubjectContent
    ? dataSubjectContent?.chapters?.ids?.map((id) => dataSubjectContent?.chapters?.entities?.[id]) || []
    : [];

    const content = (
        <>           
            <div className={`${styles.row } ${isLeftOffcanvasCollapsed ? styles.isCollapsed : ""}`}>
                <div className={`${styles.left} ${styles.isOpenContent}`}>
                    {/* {isLoadingGetSubjectContent && <p>Loading content...</p>}
                    {isErrorGetSubjectContent && <p>Error loading content: {errorGetSubjectContent?.message}</p>}
                    {isSuccessGetSubjectContent && dataChapters.length > 0 ? ( */}
                        <LibraryContent dataChapters={dataChapters}/>
                    {/* ) : (
                        <p>No content available.</p>
                    )} */}
                </div>
                <div className={`${styles.right} ${styles.isOpenContent}`}>
                    
                    <SNav isLeftOffcanvasCollapsed={isLeftOffcanvasCollapsed} />
                    <Outlet />
                </div>
            </div>
                      
        </>
    )

    return content
}

export default SLayout_2_w_offcanvas