import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE =   {
                            isLeftOffcanvasCollapsed: true,
                        }

const librarySlice = createSlice({
    name: "library",
    initialState: INITIAL_STATE,
    reducers:   {
                    setIsLeftOffcanvasCollapsed: (state, action) => {
                        if (typeof action.payload !== 'boolean') {
                            console.warn('Payload must be a boolean. Ignoring invalid value:', action.payload);
                            return; // Do not update the state if invalid
                        }
                        state.isLeftOffcanvasCollapsed = action.payload;
                    },
                }
});


export const { 
                setIsLeftOffcanvasCollapsed
             } = librarySlice.actions;

export default librarySlice.reducer;